input.form-control,
select.form-control,
textarea.form-control {
  background: transparent;
  border-color: $main-purple-clr;
  color: $agio-grey-darkest;
  height: 49px;
  border-radius: 5px !important;
  &:focus {
    border-color: $main-purple-clr;
    color: $agio-grey-darkest;
    background: white;
  }
}

textarea.form-control {
  font-weight: 500;
  font-size: 18px;
  min-height: 80px;
}

.form-control-feedback {
  width: 40px;
  height: 40px;
  line-height: 40px;
}

.input-group {
  width: 100%;
}

.input-group-addon {
  border-color: $main-purple-clr !important;
  background: $agio-grey-lightest !important;
  color: $agio-grey-darkest !important;
}

.form-block-row {
  margin-bottom: 40px;
  .addon-button {
    top: 25px;
    bottom: auto;
  }
}
.form-row {
  position: relative;
  > i.fa-spin {
    position: absolute;
    right: -35px;
    top: 8px;
    font-size: 20px;
    color: $agio-blue-active;
  }
  .addon-button {
    position: absolute;
    right: -45px;
    bottom: 2.5px;
    font-size: 13px;
    width: 30px;
    height: 30px;
    text-align: center;
    color: $agio-white;
    background: $agio-blue;
    &.circle {
      border-radius: 50%;
      &.circle-red {
        background: $brand-danger;
      }
    }
  }
}

.advanced-button {
  margin: 13px;
  margin-top: 28.5px;
  position: relative;
  &.no-label {
    margin-top: 13px;
  }
}

.toggle-row {
  margin: 10px 0;
  h4 {
    margin: 0;
    font-weight: 700;
  }
}

.has-feedback .form-control {
  padding-right: 0 !important;
}
.input-group-btn {
  button {
    height: 40px;
  }
}

.form-margin {
  margin-bottom: 15px;
}

.help-icon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background: $agio-blue-pale;
  border-radius: 10px;
  line-height: 20px !important;
  color: $agio-white;
  text-align: center;
  cursor: pointer;
}
label {
  position: relative;
}
