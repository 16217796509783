:global {
  @import "~normalize.css/normalize";
  @import "theme/app/variables";

  @import "plugins/notifications";
  @import "plugins/forms-extended";
  @import "plugins/rc-slider";
  @import "plugins/recharts";

  @import "plugins/react-toggle";
  @import "plugins/react-dates";

  // Some best-practice CSS that's useful for most apps
  // Just remove them if they're not what you want
  html {
    box-sizing: border-box;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: "Darker Grotesque", sans-serif;
  }

  code,
  kbd,
  pre,
  samp {
    font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 1em;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .app-panel-container {
    background-color: #f9fafc;
    padding: 25px;
    overflow-y: auto;
    min-height: calc(100vh - #{$navbar-height} - #{$footer-height});
  }
}

@import "theme/app/variables";

html,
body {
  font-family: "Darker Grotesque", sans-serif;
}

// start ant design overrides

.rtl {
  .ant-pagination-prev {
    transform: rotate(180deg) !important;
  }

  .ant-pagination-next {
    transform: rotate(-180deg) !important;
  }
}

.ant-switch-checked {
  background-color: $main-switch-bg-clr !important;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: $main-purple-clr !important;
}

.ant-checkbox-checked::after {
  border: 1px solid $main-purple-clr !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $main-purple-clr !important;
  border-color: $main-purple-clr !important;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  left: 10%;
  top: 40%;
}

.ant-pagination-item-active {
  border-color: $main-purple-clr !important;
}

.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: $main-purple-clr !important;
}

.ant-pagination-item-active a {
  color: $main-purple-clr !important;
}

.ant-calendar-picker-clear,
.ant-calendar-picker-icon {
  display: none;
}

.ant-input {
  color: $main-btn-clr !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  font-weight: 500;
}

.ant-input:focus,
.ant-input:hover {
  border-color: $main-input-clr-focus !important;
  box-shadow: 0 0 0 2px rgba(219, 24, 255, 0.2) !important;
}

.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $main-input-clr-focus !important;
  box-shadow: 0 0 0 2px rgba(219, 24, 255, 0.2) !important;
}

.ant-calendar-input {
  font-weight: 500;
  font-size: 16px;
}

.ant-calendar-today .ant-calendar-date {
  border-color: $main-purple-clr !important;
  color: $main-purple-clr !important;
}

.ant-calendar-selected-day .ant-calendar-date {
  background: #d8c1f7 !important;
}

.ant-calendar-date:hover {
  background: #ebe6ff !important;
}

.ant-calendar .ant-calendar-clear-btn,
.ant-calendar .ant-calendar-today-btn {
  color: $main-purple-clr;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #f3e6ff !important;

  > span {
    color: $main-purple-clr !important;
  }
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after {
  border-right: 3px solid $main-purple-clr !important;
}

.datePickerDropdown {
  .ant-calendar-time .ant-calendar-footer {
    .ant-calendar-ok-btn-disabled {
      color: rgba(0, 0, 0, 0.25) !important;
    }
  }

  .ant-calendar-time .ant-calendar-footer {
    a:not(.ant-calendar-ok-btn) {
      color: $main-purple-clr !important;
      text-decoration: none !important;

      &:hover {
        color: darken($main-purple-clr, 10%) !important;
      }
    }
  }
}

// end ant design

.text-danger {
  font-size: 15px !important;
  font-weight: 700;
}

.help-block {
  font-size: 15px !important;
  font-weight: 700;
}

.btn-primary {
  color: $main-purple-clr !important;
  background-color: #fff !important;
  border-color: $main-purple-clr !important;
}

.containerCol {
  margin-bottom: 150px;
  font-size: 16px;

  input {
    height: 50px !important;
    border-color: #1a70fb;
    border-radius: 6px !important;
  }
}

.btn-danger {
  color: #fff;
  background-color: #f22e52 !important;
  border-color: #ec7c90 !important;
}

.multiple-value-text-input .multiple-value-text-input-item {
  background: #2c97de !important;
  color: white !important;
}

.Select-control .Select-input {
  border-radius: 5px !important;
  border-color: #1d86f1 !important;
  height: 5rem !important;
  font-size: 1.8rem !important;
}

.Select-value {
  display: flex;
  align-items: center;
}

.media {
  margin-top: 3rem !important;
}

.input-group .form-control {
  z-index: 0;
}

.Select-menu-outer {
  z-index: 999 !important;
}

.check-blue {
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #ccc;
    transition: all 0.2s ease;
  }

  .react-toggle--checked {
    .react-toggle-track {
      background-color: $main-purple-clr;
    }
  }

  .react-toggle:hover.react-toggle--checked:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: $main-purple-clr;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #ccc;
  }

  .react-toggle-track-x {
    display: none;
  }

  .react-toggle-thumb {
    border: 1px solid #fff;
  }
}

.multiple-value-text-input-item-container {
  padding: 5px;
}

.multiple-value-text-input-item {
  padding: 6px !important;
  background: $main-purple-clr !important;
  margin-right: 5px !important;
  border-radius: 10px !important;
  color: white !important;
  margin-top: 15px !important;
}

.carousel-indicators li {
  background-color: #bbdbfb;
  width: 15px;
  height: 15px;
  border: none;
  margin-top: 0;
  margin-bottom: 0;
}

.carousel .carousel-indicators .active {
  background-color: #1d86f1;
  width: 15px;
  height: 15px;
}

.container,
.container-fluid {
  padding: 0;
}

.onBoadringModal {
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 18px;
    padding: 61px 0px 27px 0px;
    font-size: 24px;
    font-weight: 500;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0px 0px;
    background: linear-gradient(
      179deg,
      rgba(99, 11, 222, 0.2) 0%,
      rgba(217, 217, 217, 0) 100%
    );
    border-radius: 4px 4px 0 0;
    color: #292929;
    padding: 0;
    border-bottom: none;

    span {
      padding-left: 1.5em;
      color: #f22e52;
    }

    p {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
    }
  }

  .ant-modal-close-x {
    background: rgba(255, 255, 255, 0.5);
    width: 32px;
    height: 29.8px;
    border-radius: 50%;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .anticon {
    font-size: 12px;
    color: #121212;
  }

  .ant-modal-footer {
    border-top: none;
    padding: 10px 0px;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    margin-top: 18px;

    .confirm {
      color: #fff;
      border-radius: 8px;
      background: #630bde;
      padding: 16px 24px;
      font-size: 24px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      height: 56px;
      transition: all 0.3s ease-in-out;
      border: none;

      &:focus,
      &:hover {
        background: #630bdecc;
      }

      &:disabled {
        background: #630bde76;
      }
    }

    .cancel {
      border: 1px solid rgba(99, 11, 222, 0.2);
      background: rgba(99, 11, 222, 0.1);
      color: #630bde;
      transition: all 0.3s ease-in-out;
      height: 56px;

      &:focus,
      &:hover {
        background: rgba(99, 11, 222, 0.2);
      }
    }
  }

  .ant-progress-text {
    display: none;
  }

  .ant-progress-inner {
    background-color: #f22e5240;
    border-radius: 0;
  }

  .ant-progress-bg {
    background-color: #f22e52;
    border-radius: 0;
  }

  .ant-progress-show-info .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }

  .OnBoardingModal__smsCard___uz3m9,
  .smsCard {
    .ant-radio-checked {
      background: url("static/checkcircle.svg");
      width: 24px;
      height: 24px;
    }

    .ant-radio-inner {
      display: none;
    }
  }

  .ant-radio-wrapper {
    white-space: pre-wrap;
  }

  .OnBoardingModal__SMSInput___quFH8 {
    .ant-select-selection {
      border-radius: 4px !important;
      border: 1px solid #e3e6ef !important;
      background: #fff !important;
      width: 95% !important;
      height: 48px !important;
    }

    .ant-select-selection__rendered {
      height: 100%;
    }

    .ant-radio-group {
      width: 100%;
    }

    .ant-select-selection-selected-value {
      display: flex !important;
      align-items: center;
      height: 100%;
    }

    .ant-radio-checked .ant-radio-inner {
      border-color: #630bde;
    }

    .ant-radio-inner:after {
      background-color: #630bde;
    }
  }

  .TextBox__my-form-label___FMQef {
    color: #292929;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
  }

  .SMSConfig__contain___XNDJy {
    .col-md-6 {
      margin-top: 24px;
    }
  }

  .ant-radio {
    width: 24px;
    height: 24px;
  }

  .ant-radio-wrapper-checked {
    border-radius: 8px;
    border: 1px solid #630bde !important;
    background: #fff;

    .ant-radio {
      background: url("static/checkcircle.svg");
      width: 24px;
      height: 24px;
    }
  }
}

.ant-table {
  td {
    font-size: 16px;
    font-weight: 500;
  }
}
